.scroll-to-top {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    animation: fadeIn 1300ms ease-in-out 1s both;
    cursor: pointer;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}