.table--div {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.table--div .table--div1 {
    width: 25%;
    text-align: center;
}

.table--div h3 {
    background: #203864;
    color: #FFF;
    border: 1px solid #929292;
}
